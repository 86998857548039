import React from 'react'
import { Link } from 'gatsby'
import './car-make-card.css'

const CarInfoCard =  ({ logo, flag, alt, link, name }) => {

  return (

      <div className="car-info-card">
        <Link to={link} className="car-info-card-link">
          <img src={logo} className="logo" alt={alt}/>
          <div className="description">
            
            <span href="#" className="make-link">{name}</span>
            <img className="flag" src={flag} alt="country of origin" />

          </div>
        </Link>
      </div>
  )
}

export default CarInfoCard
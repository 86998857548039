import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SocialMediaBox from '../components/social-media-box'
import CarMakeCard from '../components/car-make-card'
import '../styles/global.css'

//images
import AcuraGrill from '../../static/grills/acura-ilx-white-front-grill-allstocksociety-600px.jpg'
import ArielGrill from '../../static/grills/ariel-nomad-tan-allstocksociety-600px.jpg'
import AudiGrill from '../../static/grills/audi-s4-front-grill-grey-allstocksociety-600px.jpg'
import BMWGrill from '../../static/grills/bmw-m4-front-grill-grey-allstocksociety-600px.jpg'
import HondaGrill from '../../static/grills/honda-type-r-front-grill-white-allstocksociety-600px.jpg'
import PorscheGrill from '../../static/grills/porsche-911-992-front-grill-green-allstocksociety-600px.jpg'
import SubaruGrill from '../../static/grills/subaru-wrx-sti-s209-white-front-allstocksociety-600px.jpg'
import VWGrill from '../../static/grills/vw-golf-r-front-grill-blue-allstocksociety-600px.jpg'

import USFlag from '../../static/country-flags/us-flag-full-size.png'
import UKFlag from '../../static/country-flags/uk-flag-full-size.png'
import JapaneseFlag from '../../static/country-flags/japan-flag-full-size.png'
import GermanFlag from '../../static/country-flags/german-flag-full-size.png'



export default function HomePage () {

  return (

    <Layout>
      
      <SEO title="OEM+ Auto Enthusiasts Community - All Stock Society" description="A community of auto enthusiasts that appreciate cars in any form. That means from bone stock, to lightly modified OEM+ style and all the way to a stripped down race car or show car." image={AudiGrill}/>

      <div className="main-heading-box">
        <h1 className="main-heading">Manufacturers</h1>
        <p>To get started, find the manufacturer you want to learn more about:</p>
      </div>
      


      <div className="grid-container">

        <CarMakeCard 
          name="Acura"
          logo={AcuraGrill}
          flag={JapaneseFlag}
          alt="Acura Logo"
          link="/acura-mods-guide"
        />

        <CarMakeCard
          name="Ariel"
          logo={ArielGrill}
          flag={UKFlag}
          alt="Ariel Logo"
          link="/ariel-mods-guide"
        />

        <CarMakeCard 
          name="Audi"
          logo={AudiGrill}
          flag={GermanFlag}
          alt="Audi logo"
          link="/audi-mods-guide"
        />

        <CarMakeCard 
          name="BMW"
          logo={BMWGrill}
          flag={GermanFlag}
          alt="BMW Logo"
          link="/bmw-mods-guide"
        />

        <CarMakeCard 
          name="Honda"
          logo={HondaGrill}
          flag={JapaneseFlag}
          alt="Honda Logo"
          link="/honda-mods-guide"
        />

        <CarMakeCard 
          name="Porsche"
          logo={PorscheGrill}
          flag={GermanFlag}
          alt="Porsche Logo"
          link="/porsche-mods-guide"
        />

        <CarMakeCard 
          name="Subaru"
          logo={SubaruGrill}
          flag={JapaneseFlag}
          alt="Subaru Logo"
          link="/subaru-mods-guide"
        />

        <CarMakeCard 
          name="Volkswagen"
          logo={VWGrill}
          flag={GermanFlag}
          alt="Volkswagen Logo"
          link="/vw-mods-guide"
        />
      </div>

      <SocialMediaBox />

    </Layout>

  )
}